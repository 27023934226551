import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { TimeSheetService } from './timesheet.service';
import { MatPaginator, MatSort, PageEvent } from '@angular/material';
import { ITimeSheet } from '../../models/IMyTimeSheet';
import { TimeSheetDataSource } from './timesheet.datasource';
import { merge, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../security/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/language/language.service';
import { APP_CONFIG, AppConfig } from '../../shared/app-config.module';
import { ViewMode } from '../../models/IProfile';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.list.component.html',
  styleUrls: ['./timesheet.component.scss']
})

export class TimeSheetComponent {

  displayedColumns = ['no', 'resourceNo', 'startDate', 'endDate'];
  timeSheet: ITimeSheet;
  dataSource: TimeSheetDataSource<ITimeSheet>;
  pageIndex: number = 0;
  pageSize: number = 5;
  length: number;
  bookmarkKey: string;
  selectedView: string;
  language: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router,
    private route: ActivatedRoute,
    private timeSheetService: TimeSheetService,
    private authService: AuthService,
    public languageService: LanguageService) {
    this.language = this.languageService.getLanguage();
    this.timeSheet = this.route.snapshot.data["timesheets"];

    this.pageSize = config.pageSize;

    if (!this.timeSheet) {
      this.timeSheet = <ITimeSheet>{};
      let user = authService.user;
      this.timeSheet.resourceNo = user.resourceNo;
      this.dataSource = new TimeSheetDataSource(this.timeSheetService);
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.view != undefined && params.view != null && params.view != "") {
        if (this.selectedView != params.view)
          this.dataSource.resetBookmark();
        this.selectedView = params.view;
        this.loadTimeSheetPage();
      }
    });
    
    //if (this.selectedView == undefined || this.selectedView == null || this.selectedView == "")
    //  this.selectedView = "all";
    //this.dataSource.setPaginator(this.paginator);
    //this.dataSource.loadTimeSheets(this.timeSheet.resourceNo, this.sort.direction, this.selectedView, this.paginator.pageIndex, this.paginator.pageSize);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    //fromEvent(this.input.nativeElement, 'keyup')
    //  .pipe(
    //    debounceTime(150),
    //    distinctUntilChanged(),
    //    tap(() => {
    //      this.paginator.pageIndex = 0;
    //      this.loadTimeSheetPage();
    //    })).subscribe();

    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap((pageEvent?: PageEvent) => {
        this.loadTimeSheetPage(pageEvent);
      })
    ).subscribe();
  }

  getCurrentUser() {
    return this.authService.getCurrentUser();
  }

  goToTimeSheet(no: string) {
    var user = this.authService.getCurrentUser();
    if (user.viewMode == ViewMode.Week) {
      //this.router.navigate(['timesheets/edit/week'], { queryParams: { view: this.selectedView, id: no }, queryParamsHandling: null });
      this.router.navigate(['timesheets/edit/week'], { queryParams: { view: this.selectedView, id: no }, queryParamsHandling: null, skipLocationChange: true});
    }
    if (user.viewMode == ViewMode.Default) {
      //this.router.navigate(['timesheets/edit/default'], { queryParams: { view: this.selectedView, id: no }, queryParamsHandling: null });
      this.router.navigate(['timesheets/edit/default'], { queryParams: { view: this.selectedView, id: no }, queryParamsHandling: null, skipLocationChange: true});
    }
  }

  loadTimeSheetPage(pageEvent?: PageEvent) {
    if (this.paginator.pageSize == undefined || this.paginator.pageSize == null)
      this.paginator.pageSize = this.pageSize;
    this.dataSource.setPaginator(this.paginator, pageEvent);
    this.dataSource.loadTimeSheets(
      this.timeSheet.resourceNo,
      this.sort.direction,
      this.selectedView,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }
}
