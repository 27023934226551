import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../shared/app-config.module';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ITimeSheetLine, ITimeSheetLineDetail, TypeLine, ITimeSheetGroup, UpdateTimeSheetLine, ITimeSheetCardModel } from '../../../models/IMyTimeSheet';
import { TimeSheetService } from '../timesheet.service';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar, MatDialog, MatAccordion, MatTabGroup, MatTabChangeEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../../../shared/validation/validation.service';
import { SelectionModel } from '@angular/cdk/collections';
import { LanguageService } from '../../../shared/language/language.service';
import { AuthService } from '../../../security/auth.service';
import { TimeSheetDetailComponent } from './timesheet.detail.base.component';
import { catchError, finalize } from 'rxjs/operators';
import { DirtyComponent } from '../../../shared/dirty/dirty-component';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewMode } from '../../../models/IProfile';
import * as Enumerable from "linq-es2015";
import * as moment from 'moment';
import { IJobTaskReference, IAbsenceCode } from '../../../models/IJobReference';
import { IValidationMessage } from '../../../models/IValidationMessage';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { OffsetDatePipe } from '../../../shared/pipes';
import { ITimeSheet } from '../../../models/IMyTimeSheet';
import { TimeSheetDataSource } from './../timesheet.datasource';
import { of } from 'rxjs';

@Component({
  selector: 'tabs-timesheet-detail',
  templateUrl: './tabs.timesheet.detail.component.html',
  styleUrls: ['../timesheet.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({opacity: 0})),
      transition('void <=> *', animate(1000)),
    ])],
  providers: [OffsetDatePipe]
})

export class TabsTimeSheetDetailComponent extends TimeSheetDetailComponent implements OnInit, AfterViewInit, DirtyComponent {

  step = -1;
  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;
  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;
  selectionLines = new SelectionModel<FormGroup>(true, []);
  timeSheetGroupForm: FormGroup;
  timeSheetNew: ITimeSheet;
  dataSourceNew: TimeSheetDataSource<ITimeSheet>;
  recordFiltered: any;
  
  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public route: ActivatedRoute,
    public timeSheetService: TimeSheetService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public validationService: ValidationService,
    public languageService: LanguageService,
    public dialog: MatDialog,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public offsetDatePipe: OffsetDatePipe) {

    super(route, timeSheetService, snackBar, translateService, validationService, languageService, dialog, authService, fb, sanitizer, config, offsetDatePipe);

    this.timeSheetGroupForm = this.fb.group({
      timeSheetGroups: this.fb.array([])
    });
  }
  
  ngAfterViewInit() {
  }

  canDeactivate() {
    return this.isDirtyLines();
  }

  addLine() {
    this.validationService.clearValidationMessage();
    var selectedTab = this.tabGroup.selectedIndex;
    var day = this.offsetDatePipe.transform(this.timeSheet.summary.dateArray[selectedTab], { format: "yyyy-MM-dd"});

    this.loading.next(true);
    this.accordion.closeAll();
    this.timeSheetService.addLine(this.timeSheet.no, TypeLine.Job, day).subscribe((updateTimeSheetLine: UpdateTimeSheetLine) => {
      if (updateTimeSheetLine) {
        updateTimeSheetLine.timeSheetLineSubForm.cost = { time_Sheet_No: updateTimeSheetLine.timeSheetLineSubForm.time_Sheet_No, line_No: updateTimeSheetLine.timeSheetLineSubForm.line_No };
        if (!this.timeSheet.timeSheetLineSubForm)
          this.timeSheet.timeSheetLineSubForm = [];
        this.timeSheet.timeSheetLineSubForm.push(updateTimeSheetLine.timeSheetLineSubForm);

        if (this.timeSheet.timeSheetLineGroups[selectedTab] == undefined || this.timeSheet.timeSheetLineGroups[selectedTab] == null)
          this.timeSheet.timeSheetLineGroups[selectedTab] = <ITimeSheetGroup><unknown>{ timeSheetLines: <ITimeSheetLine><unknown>[] };
        this.timeSheet.timeSheetLineGroups[selectedTab].timeSheetLines.push(updateTimeSheetLine.timeSheetLineSubForm);
        this.timeSheet.timeSheetDetailLine.push(updateTimeSheetLine.timeSheetDetailLine[0]);
        this.setStep(this.timeSheet.timeSheetLineGroups[selectedTab].timeSheetLines.length - 1);

        this.updateTotals();
        updateTimeSheetLine.timeSheetLineSubForm.totalQuantityOnCapacity = this.getHint(selectedTab);
      }
    }, error => {
      this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
      let obj = <IValidationMessage>{};
      error._body ? obj = JSON.parse(error._body) : obj = error;
      this.validationService.mapValidationServerMessage(obj, this.form);
    }).add(() => { this.loading.next(false); });
  }

  onChangeDetailDayTab(line: ITimeSheetLine, fieldNo: number) {
    this.onChangeDetailDay(line, fieldNo);
  }
//Funzione Prossimo foglio presenza selezionando il numero successivo
  //Ricercando la Key del record nell'array
  prevRecord(timeSheet: ITimeSheetCardModel) {
    this.loading.next(true);
    var Status = '';
    this.activatedRoute.queryParams.subscribe(params => {
      Status = params.view;
    });
    this.timeSheetService.findTimeSheets(this.timeSheet.resource_No, 'asc', Status, 0, 0, 0, '')
      .pipe(catchError(() => of([])), finalize(() => this.loading.next(false)))
      .subscribe(result => {
        this.recordFiltered = ((<any>result));
        var PrevRecordNo = '';
        if (this.recordFiltered) {
          this.recordFiltered.forEach((r, i) => {
            //if (this.recordFiltered.totalCount-1 != i) {
              if (r.no == timeSheet.no) {
                if (i != 0) {
                  var previdx = i - 1;
                  PrevRecordNo = this.recordFiltered[previdx].no;
                }
              }
            //}
          });
        }
        if (PrevRecordNo == '') PrevRecordNo = timeSheet.no;

        this.timeSheetService.getPrevRecord(PrevRecordNo, this.timeSheet.resource_No).subscribe(r => {
          if (r) {
            this.onAfterGetTimeSheet(r);
          }
        }).add(() => { this.loading.next(false); });

      }).add(() => { this.loading.next(true); });
  }
  /*
  prevRecord() {
    this.loading.next(true);
    this.timeSheetService.getPrevRecord(this.timeSheet.no, this.timeSheet.resource_No).subscribe(r => {
      if (r) {
        this.timeSheet = r;
        this.setColumns();
        this.accordion.closeAll();
      }
    }).add(() => { this.loading.next(false); });
  }
  */
  //Funzione Prossimo foglio presenza selezionando il numero successivo
  //Ricercando la Key del record nell'array
  nextRecord(timeSheet: ITimeSheetCardModel) {
    this.loading.next(true);
    var Status = '';
    this.activatedRoute.queryParams.subscribe(params => {
      Status = params.view;
    });
    this.timeSheetService.findTimeSheets(this.timeSheet.resource_No, 'asc', Status, 0, 0, 0, '')
      .pipe(catchError(() => of([])), finalize(() => this.loading.next(false)))
      .subscribe(result => {
        this.recordFiltered = ((<any>result));
        var NextRecordNo = '';
        if (this.recordFiltered) {
          this.recordFiltered.forEach((r, i) => {
            if (this.recordFiltered.totalCount - 1 != i) {
              if (r.no == timeSheet.no) {
                  var nextidx = i + 1;
                  NextRecordNo = this.recordFiltered[nextidx].no;
              }
            }
          });
        }
        if (NextRecordNo == '') NextRecordNo = timeSheet.no;

        this.timeSheetService.getNextRecord(NextRecordNo, this.timeSheet.resource_No).subscribe(r => {
          if (r) {
            this.onAfterGetTimeSheet(r);
          }
        }).add(() => { this.loading.next(false); });

      }).add(() => { this.loading.next(true); });
  }

  /*
  nextRecord() {
    this.loading.next(true);
    this.timeSheetService.getNextRecord(this.timeSheet.no, this.timeSheet.resource_No)
      .pipe(finalize(() => { this.loading.next(false); }))
      .subscribe(r => {
        this.onAfterGetTimeSheet(r);
    });
  }
  */

  showIfDay(row: ITimeSheetLine, j): boolean {
    if (!this.tabGroup)
      return false;
    if (this.tabGroup.selectedIndex == undefined || this.tabGroup.selectedIndex == null)
      return false;
    j = this.tabGroup.selectedIndex;
    switch (j) {
      case 0:
        if (row && row.field1 != null)
          return true;
      case 1:
        if (row && row.field2 != null)
          return true;
      case 2:
        if (row && row.field3 != null)
          return true;
      case 3:
        if (row && row.field4 != null)
          return true;
      case 4:
        if (row && row.field5 != null)
          return true;
      case 5:
        if (row && row.field6 != null)
          return true;
      case 6:
        if (row && row.field7 != null)
          return true;
    }
    return false;
  }

  selectTab(i: number) {
    this.tabGroup.selectedIndex = i;
    this.accordion.closeAll();
  }

  selectedTabChange(event: MatTabChangeEvent) {
    this.step = -1;
    //this.selection.clear();
  }

  //isAllSelectedRows() {
  //  var selectedTab = this.tabGroup.selectedIndex;
  //  const numSelected = this.selection.selected.length;
  //  const numRows = this.timeSheet.timeSheetLineGroups[selectedTab].timeSheetLines.length;
  //  return numSelected === numRows;
  //}

  //masterToggleRows(event: any) {
  //  var selectedTab = this.tabGroup.selectedIndex;
  //  if (event.checked) {
  //    this.timeSheet.timeSheetLineGroups.forEach((group: ITimeSheetGroup, i: number) => {
  //      if (i == selectedTab) {
  //        group.timeSheetLines.forEach((row) => {
  //          this.selection.select(<any>row);
  //        });
  //      }
  //      else {
  //        group.timeSheetLines.forEach((row) => {
  //          this.selection.deselect(<any>row);
  //        });
  //      }
  //    });
  //  } else {
  //    this.timeSheet.timeSheetLineGroups[selectedTab].timeSheetLines.forEach((row) => {
  //      this.selection.deselect(<any>row);
  //    });
  //  }    
  //}

  isAllSelectedRows() {
    const numSelected = this.selection.selected.length;
    const numRows = this.timeSheet.timeSheetLineSubForm.length;
    return numSelected === numRows;
  }

  masterToggleRows() {
    this.isAllSelectedRows() ?
      this.selection.clear() :
      this.timeSheet.timeSheetLineSubForm.forEach((row) => {
        this.selection.select(<any>row);
      });
  }

  getTabLabel(j: number) {
    var label = this.timeSheet.summary.dateDescription[j] + ' - ' + this.getHint(j);
    return label;
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  loadGroups() {
    this.timeSheetGroupForm = this.fb.group({
      timeSheetGroups: this.fb.array([])
    });

    if (this.timeSheet.summary && this.timeSheet.summary.dateArray) {
      for (var j = 0; j < this.timeSheet.summary.dateArray.length; j++) {
        this.addGroup(this.timeSheet.summary.dateArray[j]);
      }
    }
    var dateFormat = "YYYY-MM-DD";
    var user = this.authService.getCurrentUser();
    if (user.viewMode == ViewMode.Week) {
      if (this.timeSheet.timeSheetLineSubForm) {
        for (var j = 0; j < this.timeSheet.summary.dateArray.length; j++) {
          var date = this.timeSheet.summary.dateArray[j];
          Enumerable.from(this.timeSheet.timeSheetLineSubForm).ToArray().forEach((timeSheetLine: ITimeSheetLine) => {
            var existDetails = Enumerable.from(this.timeSheet.timeSheetDetailLine).Any(x =>
              (<ITimeSheetLineDetail>x).time_Sheet_No == timeSheetLine.time_Sheet_No &&
              (<ITimeSheetLineDetail>x).time_Sheet_Line_No == timeSheetLine.line_No &&
              this.offsetDatePipe.transform(moment((<ITimeSheetLineDetail>x).date)).format(dateFormat) == this.offsetDatePipe.transform(moment(date)).format(dateFormat));
            if (existDetails === true) {
              this.addRow(timeSheetLine, j);
            } else {
              if (this.offsetDatePipe.transform(moment((<ITimeSheetLine>timeSheetLine).date)).format(dateFormat) == this.offsetDatePipe.transform(moment(date)).format(dateFormat)) {
                this.addRow(timeSheetLine, j);
              }
            }
          });
        }
      }
    }
  }

  addGroup(date: Date) {
    let control = <FormArray>this.timeSheetGroupForm.controls.timeSheetGroups;
    control.push(
      this.fb.group({
        date: [date],
        timeSheetRows: this.fb.array([])
      })
    )
  }

  deleteGroup(index) {
    let control = <FormArray>this.timeSheetGroupForm.controls.timeSheetGroups;
    control.removeAt(index)
  }

  addRow(line: ITimeSheetLine, j: number) {
    let values: number[] = [];
    values = this.getQuantity(line);
    let control = (<FormArray>(<FormGroup>(<FormArray>this.timeSheetGroupForm.controls.timeSheetGroups).controls[j]).controls.timeSheetRows).controls;
    control.push(
      this.fb.group({
        'selected': [{ value: line.selected, disabled: !line.editable }, []],
        'key': [{ value: line.key, disabled: !line.editable }, []],
        'time_Sheet_No': [{ value: line.time_Sheet_No, disabled: !line.editable }, []],
        'line_No': [{ value: line.line_No, disabled: !line.editable }, []],
        'job_No': [{ value: line.job_No, disabled: !line.editable }, []],
        'cause_of_Absence_Code': [{ value: line.cause_of_Absence_Code, disabled: !line.editable }, []],
        'job_Task_No': [{ value: line.job_Task_No, disabled: !line.editable }, []],
        'work_Type_Code': [{ value: line.work_Type_Code, disabled: !line.editable }, [Validators.required]],
        'description': [{ value: line.description, disabled: !line.editable }, []],
        'type': [{ value: line.type, disabled: !line.editable }, []],
        'field1': [{ value: values[0], disabled: !line.editable }, []],
        'field2': [{ value: values[1], disabled: !line.editable }, []],
        'field3': [{ value: values[2], disabled: !line.editable }, []],
        'field4': [{ value: values[3], disabled: !line.editable }, []],
        'field5': [{ value: values[4], disabled: !line.editable }, []],
        'field6': [{ value: values[5], disabled: !line.editable }, []],
        'field7': [{ value: values[6], disabled: !line.editable }, []],
        'total_Quantity': [{ value: line.total_Quantity, disabled: true }, []],
        'totalQuantityOnCapacity': [{ value: line.totalQuantityOnCapacity, disabled: true }, []],
        'status': [{ value: line.status, disabled: true }, []],
        'submitted': [{ value: false }, []]
      }))
  }

  deleteRow(control, index) {
    control.removeAt(index)
  }

  onClickSubmitLine(line: FormGroup) {
    this.clearSelectionLines();
    line.get('submitted').setValue(true);
    line.get('selected').setValue(true);
    this.selectionLines.select(line);
  }

  onSubmitFormGroup() {
    var toSubmitLines = [];
    var groups = (<FormGroup>this.timeSheetGroupForm.controls.timeSheetGroups).controls;
    Enumerable.from(<any>groups).ToArray().forEach((group: FormGroup) => {
      var rows = group.get('timeSheetRows');
      if (rows != null) {
        let lines = (<FormGroup>rows).controls;
        Enumerable.from(<any>lines).ToArray().forEach((line: FormGroup) => {
          if (line != null) {
            if (line.get('selected').value === true) {
              this.collectErrors(line);
              if (line.valid)
                toSubmitLines.push(line);
            }
          }
        });
      }
    });

    if (toSubmitLines != null) {
      Enumerable.from(toSubmitLines).ToArray().forEach((line: FormGroup) => {
        this.loading.next(true);
        this.timeSheetService.updateLine(line.value).subscribe(r => {
          line.get('submitted').setValue(false);
          this.clearSelectionLines();
          this.updateTotals();
          this.showMessage(this.translateService.instant('SAVE_SUCCESS_MESSAGE'), '', false);
        }, error => {
            this.showMessage(this.translateService.instant('ERROR_MESSAGE'), '', true);
            let obj = <IValidationMessage>{};
            error._body ? obj = JSON.parse(error._body) : obj = error;
            this.validationService.mapValidationServerMessage(obj, this.timeSheetGroupForm);
        }).add(() => {
          this.loading.next(false);
        });
      });
    }
  }

  isFormGroup(control: AbstractControl): control is FormGroup {
    control.updateValueAndValidity();
    return !!(<FormGroup>control).controls;
  }

  collectErrors(control: AbstractControl): any | null {
    if (this.isFormGroup(control)) {
      return Object.entries(control.controls)
        .reduce(
          (acc, [key, childControl]) => {
            const childErrors = this.collectErrors(childControl);
            if (childErrors) {
              acc = { ...acc, [key]: childErrors };
            }
            return acc;
          },
          null
        );
    } else {
      return control.errors;
    }
  }

  onLookupJobControl(row: FormGroup, value: string) {
    if (value == undefined || value == null) {
      value = "";
      row.value.job_Task_No = "";
      row.value.description = "";
      (<ITimeSheetLine><unknown>row).filteredJobTasks = [];
    }
    this.isLoading = true;
    var resourceNo = this.authService.user.resourceNo;
    return this.timeSheetService.findJobs(this.timeSheet.no, value.toLowerCase(), resourceNo, null, 1, null, null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        (<ITimeSheetLine><unknown>row).filteredJobs = results;
      });
  }

  onKeyUpJobControl(row: FormGroup, searchValue: string) {
    if (searchValue == null || searchValue == "") {
      row.value.job_Task_No = "";
      row.value.description = "";
      (<ITimeSheetLine><unknown>row).filteredJobTasks = [];
    }
  }

  onFocusLookupJobControl(row: FormGroup) {
    if (row) {
      var value = "";
      if (row.value.job_No != undefined && row.value.job_No != null) {
        value = row.value.job_No;
      }
      this.onLookupJobControl(row, "");
    }
  }

  lookupJobTasksControl(row: FormGroup, value: string) {
    if (row.value.job_No == undefined || row.value.job_No == null || row.value.job_No == "") {
      (<ITimeSheetLine><unknown>row).filteredJobTasks = [];
      row.value.job_Task_No = "";
      row.value.description = "";
      return;
    }
    if (value == undefined || value == null)
      value = "";
    var resourceNo = this.authService.user.resourceNo;
    return this.timeSheetService.findJobTasks(this.timeSheet.no, row.value.job_No, value.toLowerCase(), resourceNo, null, 1, null, null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        (<ITimeSheetLine><unknown>row).filteredJobTasks = results;
      });
  }

  lookupWorkTypeCodeControl(row: FormGroup, value: string) {
    if (value == undefined || value == null)
      value = "";
    this.isLoading = true;
    return this.timeSheetService.findWorkTypeCodes(value.toLowerCase(), null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        (<ITimeSheetLine><unknown>row).filteredWorkTypesCodes = results;
      });
  }

  onFocusWorkTypeCodeControl(row: FormGroup) {
    if (row) {
      var value = "";
      if (row.value.work_Type_Code != undefined && row.value.work_Type_Code != null)
        value = row.value.work_Type_Code;
      this.lookupWorkTypeCodeControl(row, "");
    }
  }

  lookupAbsenceCodeControl(row: FormGroup, value: string) {
    if (value == undefined || value == null)
      value = "";
    this.isLoading = true;
    return this.timeSheetService.findAbsencesCodes(this.timeSheet.no, value.toLowerCase(), null, 1, null, null)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((results) => {
        (<ITimeSheetLine><unknown>row).filteredAbsencesCodes = results;
      });
  }

  onFocusLookupAbsenceCodeControl(row: FormGroup) {
    if (row) {
      var value = "";
      if (row.value.cause_of_Absence_Code != undefined && row.value.cause_of_Absence_Code != null)
        value = row.value.cause_of_Absence_Code;
      row.value.cause_of_Absence_Code = value;
      this.lookupAbsenceCodeControl(row, "");
    }
  }

  onFocusLookupJobTaskControl(row: FormGroup) {
    if (row) {
      var value = "";
      if (row.value.job_Task_No != undefined && row.value.job_No != null)
        value = row.value.job_Task_No;
      this.lookupJobTasksControl(row, "");
    }
  }

  selectJobControl(row: FormGroup, value: any) {
    if (value == undefined || value == null)
      return;
    row.value.job_Task_No = "";
    row.value.work_Type_Code = "";
    row.value.extDocMandatory = false;
    row.value.chargeable = false;
    row.value.work_Type_Code = "";
    row.value.description = "";
  }

  selectJobTaskControl(row: FormGroup, value: any) {
    if (value == undefined || value == null)
      return;
    if ((<ITimeSheetLine><unknown>row).filteredJobTasks) {
      let list: IJobTaskReference[] = (<ITimeSheetLine><unknown>row).filteredJobTasks;
      if (list) {
        var option = <IJobTaskReference>Enumerable.from(list).FirstOrDefault(x => (<IJobTaskReference>x).job_Task_No == value);
        if (option && option.description) {
          row.value.description = option.description;
        }
        if (option && option.job_Task_No) {
          var line = <ITimeSheetLine>Enumerable.from(this.timeSheet.timeSheetLineSubForm).FirstOrDefault(x => (<any>x).line_No == row.value.line_No);
          if (line)
            line.job_Task_No = option.job_Task_No;
          row.value.job_Task_No = option.job_Task_No;
          row.value.extDocMandatory = option.extDocMandatory;
          row.value.chargeable = option.chargeable;
          row.value.work_Type_Code = option.defaultWorkTypeCode;
        }
      }
    }
  }

  selectAbsenceCodeControl(row: FormGroup, value: any) {
    if (value == undefined || value == null)
      return;
    if (row.value.cause_of_Absence_Code) {

      let list: IAbsenceCode[] = (<ITimeSheetLine><unknown>row).filteredAbsencesCodes;
      if (list) {
        var option = <IAbsenceCode>Enumerable.from(list).FirstOrDefault(x => (<IAbsenceCode>x).code == value);
        if (option && option.code) {
          this.timeSheetService.findAbsencesCodes(this.timeSheet.no, row.value.cause_of_Absence_Code, null, 1, null, null).subscribe((results) => {
            if (results[0])
              row.value.description = results[0].description;
          });
        }

      }
    }
    //this.updateView();
  }

  selectWorkTypeControl(row: FormGroup, value: any) {
    if (value == undefined || value == null)
      return;
    row.get('work_Type_Code').setValue(value);
  }

  changeTypeControl(row: FormGroup, type: number) {
    if (type == TypeLine.Job) {
      if (row.value.cause_of_Absence_Code != "") {
        row.value.cause_of_Absence_Code = "";
        row.value.description = "";
        row.value.work_Type_Code = "";
        this.emptyLineControl(row);
      }
    }

    if (type == TypeLine.Absence) {
      if (row.value.job_No != "") {
        row.value.job_No = "";
        row.value.job_Task_No = "";
        row.value.description = "";
        row.value.work_Type_Code = "";
        this.emptyLineControl(row);
      }
    }
    if (type == TypeLine.Allowance) {
      if (row.value.cause_of_Absence_Code != "") {
        row.value.cause_of_Absence_Code = "";
        row.value.description = "";
      }
      this.emptyLineControl(row);
    }
  }

  emptyLineControl(row: FormGroup) {
    row.value.field1 = 0;
    row.value.field2 = 0;
    row.value.field3 = 0;
    row.value.field4 = 0;
    row.value.field5 = 0;
    row.value.field6 = 0;
    row.value.field7 = 0;
  }

  isAllSelectedLines() {
    const numSelected = this.selectionLines.selected.length;
    const numRows = this.timeSheet.timeSheetLineSubForm.length;
    return numSelected === numRows;
  }

  masterToggleLines() {
    if (this.isAllSelectedLines())
      this.selectionLines.clear()
    else {
      var groups = (<FormGroup>this.timeSheetGroupForm.controls.timeSheetGroups).controls;
      Enumerable.from(<any>groups).ToArray().forEach((group: FormGroup) => {
        var rows = group.get('timeSheetRows');
        if (rows != null) {
          let lines = (<FormGroup>rows).controls;
          Enumerable.from(<any>lines).ToArray().forEach((line: FormGroup) => {
            if (line != null)
              this.selectionLines.select(line);
          });
        }
      });
    }
  }

  clearSelectionLines() {
    this.selectionLines.clear();
    var groups = (<FormGroup>this.timeSheetGroupForm.controls.timeSheetGroups).controls;
    Enumerable.from(<any>groups).ToArray().forEach((group: FormGroup) => {
      var rows = group.get('timeSheetRows');
      if (rows != null) {
        let lines = (<FormGroup>rows).controls;
        Enumerable.from(<any>lines).ToArray().forEach((line: FormGroup) => {
          if (line != null)
            line.get('selected').setValue(false);
        });
      }
    });
  }
}
